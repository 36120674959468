import { Link } from 'gatsby';
import * as React from 'react';
import './menu.scss';

interface Props {}

const Menu: React.FC<Props> = (props) => {
  return (
    <div className="menu">
      <Link to="/">Map</Link>
      <Link to="/about">About</Link>
      <Link to="/data">Data</Link>
      <Link to="/categories">Categories</Link>
      <Link className="btn green" to="/signup">
        Join the map
      </Link>
      <Link to="/data" className="sublink">
        View & download data list
      </Link>
      <Link to="/terms" className="sublink">
        Terms & Privacy
      </Link>
    </div>
  );
};

export default Menu;
