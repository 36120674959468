import * as React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import logo from '../assets/logo.svg';
import burger from '../assets/icons/burgermenu.svg';
import cross from '../assets/icons/cross.svg';

import './shell.scss';
import { HeaderQuery } from '../generated/graphql';
import Menu from '../pages/menu';
import useMedia from '../hooks/useMediaQuery';

const Shell: React.FC = ({ children }) => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const isMobile = useMedia(['(max-width: 768px)', '(min-width: 768px)'], [true, false], false);

  const data = useStaticQuery<HeaderQuery>(graphql`
    query Header {
      site {
        siteMetadata {
          siteName
        }
      }
    }
  `);

  const siteName = data.site!.siteMetadata!.siteName;

  return (
    <>
      <Helmet defaultTitle={siteName || ''} titleTemplate={`%s — ${siteName}`} htmlAttributes={{ lang: 'en' }}>
        <meta
          name="description"
          content="A snapshot of the Tech for Good companies and support community in Bristol and Bath compiled from crowdsourced data."
        />
      </Helmet>

      <>
        <header>
          <div className="fixed-width">
            <Link to="/">
              <img alt="Tech for Good: Bristol & Bath" src={logo} />
            </Link>

            {isMobile ? (
              <div onClick={() => setMenuOpen(!menuOpen)}>
                <img className="burger-menu" src={!menuOpen ? burger : cross} />
              </div>
            ) : (
              <nav>
                <Link to="/">Map</Link>
                <Link to="/about">About</Link>
                <Link to="/data">Data</Link>
                <Link to="/categories">Categories</Link>
                <Link className="btn green" to="/signup">
                  Join the map
                </Link>
              </nav>
            )}
          </div>
        </header>
        {menuOpen ? <Menu /> : <main>{children}</main>}
      </>
    </>
  );
};

export default Shell;
