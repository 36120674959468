import * as React from 'react';
import './footer.scss';

import rocketmakers from '../assets/rm-logo.svg';
import techspark from '../assets/techspark-logo.svg';
import techforgood from '../assets/techforgood-SW.png';
import logo from '../assets/logo.svg';
import twitter from '../assets/icons/twitter2.svg';
import mail from '../assets/icons/mail3.svg';
import linkedin from '../assets/icons/linkedin.svg';
import { Link } from 'gatsby';

interface IFooterProps {
  isCategories?: boolean;
}

const Footer: React.FunctionComponent<IFooterProps> = ({ isCategories }) => {
  return (
    <div className="footer" data-is-categories={isCategories}>
      <div className="fixed-width">
        <div className="logos-container">
          <div className="top">
            <img src={rocketmakers} className="rocketmakers" /> <img src={techspark} />
          </div>
          <div className="bottom">
            <img src={logo} className="brand-logo" />
            <div className="tech-for-good-container">
              <img src={techforgood} className="tech-for-good" />
            </div>
          </div>
        </div>
        <div className="links">
          <Link to="/">Map</Link>
          <Link to="/data">Data</Link>
          <Link to="/about">About</Link>
          <Link to="/signup">Join the map</Link>
          <Link to="/terms">Terms and Privacy</Link>
        </div>
        <div className="links contact">
          <i>Contact</i>
          <a href="mailto:briony@rocketmakers.com" target="_blank">
            <img src={mail} />
            <p>briony@rocketmakers.com</p>
          </a>
          <a href="https://www.linkedin.com/company/rocketmakers/mycompany/" target="_blank">
            <img src={linkedin} />
            <p>Linkedin</p>
          </a>
          <a href="https://twitter.com/rocketmakers" target="_blank">
            <img src={twitter} />
            <p>Twitter</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
